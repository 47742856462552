var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"features -s-fc-mt-ignore",attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"container"},[(_vm.blok.list && _vm.blok.list.length)?_c('div',{staticClass:"features__list -g-d-g -g-jc-sb"},_vm._l((_vm.blok.list),function(item,idx){return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],key:item._uid,staticClass:"features__item",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":idx && 150 * idx}},[(_vm.iconFilename(item) || item.title)?_c('div',{staticClass:"features__item-top"},[(_vm.iconFilename(item))?_c('div',{staticClass:"features__item-icon"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm._f("sbImageService")(_vm.iconFilename(item)),"alt":item.icon.alt,"width":"40","height":"40"}})]):_vm._e(),_vm._v(" "),(item.title)?_c('h2',{staticClass:"features__item-title -h2",domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e()]):_vm._e(),_vm._v(" "),(item.description)?_c('p',{staticClass:"features__item-descr -t3 -ws-pl",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }